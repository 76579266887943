const Collaborators1 = [
    {
        logo: "/row2-col2.png"
    },
    {
        logo: "/row1-col1.png"
    },
    {
        logo: "/row1-col4.png"
    },
    {
        logo: "/caspia-logo.png"
    },
]

export default Collaborators1