const coconutOil = [
    {
        image: "/product-photoshoot-min.png"
    },
    {
        image: "/product-photoshoot-2-min.png"
    },
    {
        image: "/product-photoshoot-3-min.png"
    }
]

export default coconutOil;