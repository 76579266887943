const virginOil = [
    {
        image: "/virgin_oil1-min.png"
    },
    {
        image: "/virgin_oil2-min.png"
    },
    {
        image: "/virgin_oil3-min.png"
    }
]

export default virginOil;